import initTriggers from './components/triggers';
import initSliders from './components/sliders';
import initLightbox from './components/lightbox';

import { bindScrollTop, showLinkToTop, hideLinkToTop } from "./components/arrow-to-top";
import { initMaps } from './map-components/mapbox-maps';
import _debounce from 'lodash/debounce';
import { getWindowScrollPosition, getWindowSizes, addClass, removeClass } from "./components/helpers";
import init from "./vbcn";
import vbcnAdditions from "./components/vbcn-additions";

require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();
require('element-remove');

function initGlobal() {
    init();
    vbcnAdditions();

    initTriggers();
    initSliders();
    initLightbox();
    bindScrollTop();
}

const triggerOffset = 200;

const scrollHandler = _debounce(function(event) {
    let st = getWindowScrollPosition().y;
    // let wh = getWindowSizes().height;
    const header = document.getElementById('header');

    if (st >= triggerOffset) {
        initMaps();
        addClass(header, 'header-scrolled')
    } else {
        removeClass(header, 'header-scrolled')
    }

    if (st >= 500) {
        showLinkToTop();
    } else {
        hideLinkToTop();
    }
}, 10, {leading: true, trailing: false});


document.addEventListener( 'DOMContentLoaded', function( event ) {
    initGlobal();

    window.addEventListener('scroll', scrollHandler);
});
