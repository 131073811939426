"use strict";

import $ from "jquery";
import magnificPopup from "magnific-popup";

const defaults = {
    selectors: {
        lightbox: '.js-lightbox',
    },
};

export default function initLightbox () {
    let lightbox = document.querySelector(defaults.selectors.lightbox);

    if (!lightbox) return false;
    $(defaults.selectors.lightbox).magnificPopup({type:'image',gallery: {
        enabled: true,
        navigateByImgClick: true,

        arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

        tPrev: 'Previous (Left arrow key)', // title for left button
        tNext: 'Next (Right arrow key)', // title for right button
        tCounter: '<span class="mfp-counter">%curr% of %total%</span>' // markup of counter
    }});
}
