import $ from 'jquery';
import { initMaps } from './../map-components/mapbox-maps';

function loadScript(uid){
    var ref = window.document.getElementsByTagName('script')[0];
    var script = window.document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + uid;
    ref.parentNode.insertBefore(script, ref);

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', uid);
}

function showMap() {
    var mapContainer = document.querySelector('.map');
    var staticMap = document.querySelector('.static-map');
    if(mapContainer) {
        initMaps();
        staticMap.style.display = 'none';
        mapContainer.style.display = 'block';
    }
}

function triggerResize() {
    var resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
}

function labnolThumb(id) {
    var thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg">',
        play = '<div class="play"></div>';
    return thumb.replace("ID", id) + play;
}

function labnolIframe() {
    // console.log("time"+this.dataset.time);
    var iframe = document.createElement("iframe");
    var embed = "https://www.youtube-nocookie.com/embed/ID?autoplay=1\&start=START";
    embed = embed.replace("ID", this.dataset.id)
    embed = embed.replace("START", this.dataset.time)
    iframe.setAttribute("src", embed);
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    this.parentNode.replaceChild(iframe, this);
}

function getVideos() {
    var div, n, v = document.getElementsByClassName("youtube-player");
    for (n = 0; n < v.length; n++) {
        div = document.createElement("div");
        div.setAttribute("data-id", v[n].dataset.id);
        div.setAttribute("data-time", v[n].dataset.time);
        console.log(v[n].dataset.id);
        div.innerHTML = labnolThumb(v[n].dataset.id);
        div.onclick = labnolIframe;
        v[n].appendChild(div);
    }
}

function showVideos() {
    var videoEmbeds = document.querySelectorAll('.youtube-player');
    for (var i = 0, len = videoEmbeds.length; i < len; i++) {
        videoEmbeds[i].style.display = 'block';
        getVideos();
    }
    triggerResize();
}

function loadGTM() {
    if(window.cookieState && window.cookieState.cookiesAllowed && window.cookieState.allowedData.options.hasOwnProperty('gtm') && window.cookieState.allowedData.options['gtm'] ) {
        loadScript('UA-114189345-1');
    }
}

function loadVideos() {
    // console.log('videosLoading');

    if(window.cookieState && window.cookieState.cookiesAllowed && window.cookieState.allowedData.options.hasOwnProperty('youtube') && window.cookieState.allowedData.options['youtube'] ) {
        showVideos();
    } else {
        var initVideosButton = document.querySelectorAll('.js-vbcn-execute-video');
        for (var i = 0, len = initVideosButton.length; i < len; i++) {
            initVideosButton[i].addEventListener('click', showVideos);
        }
    }
}

function loadMap() {
    // console.log('mapLoading');
    if(window.cookieState && window.cookieState.cookiesAllowed && window.cookieState.allowedData.options.hasOwnProperty('mapbox') && window.cookieState.allowedData.options['mapbox'] ) {
        showMap();
    } else {
        var initMapsButton = document.querySelectorAll('.js-vbcn-execute-mapbox');
        for (var i = 0, len = initMapsButton.length; i < len; i++) {
            initMapsButton[i].addEventListener('click', showMap);
        }
    }
}

function checkLoaders() {
    loadGTM();
    loadMap();
    loadVideos();
}

export default function vbcnAdditions() {

    checkLoaders();

    var cookieAccept = document.querySelector('#vbcn-accept-preset');
    var cookieSave = document.querySelector('#vbcn-save');

    cookieAccept.addEventListener('click', checkLoaders);
    cookieSave.addEventListener('click', checkLoaders);

}
