"use strict";

require('smoothscroll-polyfill').polyfill();

const defaults = {
    selectors: {
        arrowToTop: '#to-top',
    },
    settings: {
        scrollDuration: 400,
    }
};

let linkToTop = null;

export function bindScrollTop() {
    let tempLinkToTop = document.querySelector(defaults.selectors.arrowToTop);

    if (!tempLinkToTop) return false;

    linkToTop = tempLinkToTop;

    linkToTop.addEventListener('click', function (event) {
        event.preventDefault();
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    })
}

export function showLinkToTop() {
    linkToTop.style.display = "block";
}

export function hideLinkToTop() {
    linkToTop.style.display = "none";

}


