import Flickity from 'flickity-imagesloaded';

export default function initSliders() {

    // Slideshow liste
    var mainCarousels = document.querySelectorAll('.main-carousel');
    for (var i = 0; i < mainCarousels.length; i++) {
        var carousel = mainCarousels[i];
        var slides = carousel.querySelectorAll('.carousel-cell');
        if(slides.length > 1)
        {
            new Flickity(carousel, {
                cellAlign: 'left',
                contain: false,
                autoPlay: false,
                adaptiveHeight: true,
                imagesLoaded: true,
                prevNextButtons: false,
                pageDots: true
            });
        }
        else
        {
            new Flickity(carousel, {
                cellAlign: 'left',
                contain: false,
                autoPlay: false,
                adaptiveHeight: true,
                imagesLoaded: true,
                prevNextButtons: false,
                pageDots: false
            });
        }
    }

    // Accordions
    let accordionTriggers = document.getElementsByClassName('js-accordion-button');

    for (let i = 0; i < accordionTriggers.length; i++) {
        accordionTriggers[i].addEventListener('click', function () {

            let accordionContent = this.nextElementSibling;

            if (this.classList.contains('active')) {
                this.classList.remove('active');
                this.nextElementSibling.style.maxHeight = this.nextElementSibling.scrollHeight + 'px';
                setTimeout(function () {
                    accordionContent.style.maxHeight = null;
                }, 10);
            } else {
                this.classList.add('active');
                this.nextElementSibling.style.maxHeight = this.nextElementSibling.scrollHeight + 'px';
                setTimeout(function () {
                    accordionContent.style.maxHeight = 'initial';
                }, 210);
            }

        });
    }

    // Complex Accordions
    let cArccordionTriggers = document.getElementsByClassName('js-acc-headline-trigger');

    for (let i = 0; i < cArccordionTriggers.length; i++) {
        cArccordionTriggers[i].addEventListener('click', function () {

            let cAccordionContent = this.parentNode.nextElementSibling;

            if (this.classList.contains('active')) {
                this.classList.remove('active');
                this.parentNode.nextElementSibling.style.maxHeight = this.parentNode.nextElementSibling.scrollHeight + 'px';
                setTimeout(function () {
                    cAccordionContent.style.maxHeight = null;
                }, 10);
            } else {
                this.classList.add('active');
                this.parentNode.nextElementSibling.style.maxHeight = this.parentNode.nextElementSibling.scrollHeight + 'px';
                setTimeout(function () {
                    cAccordionContent.style.maxHeight = 'initial';
                }, 210);
            }

        });
    }
}