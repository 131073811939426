import { fadeIn, fadeOut } from './helpers';

export default function triggers() {

    // Menu Logic
    const menuTrigger = document.getElementById('trigger-menu');
    const searchTrigger = document.getElementById('trigger-search');
    const menu = document.getElementById('nav');
    const search = document.getElementById('search');
    const header = document.getElementById('header');

    function openMenu() {

        var withoutScrolls = document.documentElement.clientWidth;
        var withScrolls = window.innerWidth;
        var finalWidth = (withScrolls - withoutScrolls) + 'px';

        document.body.style.overflowY = 'hidden';
        menuTrigger.classList.add('trigger-menu-active');
        //menu.style.display = 'block';
        fadeIn(menu);

        header.style.right = finalWidth;
        document.getElementsByTagName('body')[0].style.paddingRight = finalWidth;
    }

    function openSearch() {
        searchTrigger.classList.add('trigger-search-active');
        //search.style.display = 'block';
        fadeIn(search);

        var searchInput = document.getElementById('tx-indexedsearch-searchbox-sword');
        if(searchInput) {
            searchInput.focus();
        }
    }

    function closeMenu() {

        var withoutScrolls = document.documentElement.clientWidth;
        var withScrolls = window.innerWidth;
        var finalWidth = (withScrolls - withoutScrolls) + 'px';


        document.getElementsByTagName('body')[0].style.marginRight = finalWidth;
        document.getElementsByTagName('body')[0].style.marginRight = '0';


        menuTrigger.classList.remove('trigger-menu-active');
        //menu.style.display = 'none';
        fadeOut(menu);
        setTimeout(function() {
            header.style.right = '0';
            document.getElementsByTagName('body')[0].style.paddingRight = '0';

            document.body.style.overflowY = 'auto';
        }, 200);

    }

    function closeSearch() {
        searchTrigger.classList.remove('trigger-search-active');
        //search.style.display = 'none';
        fadeOut(search);
    }

    function menuLogic() {
        if(menuTrigger.classList.contains('trigger-menu-active')) {
            closeSearch();
            closeMenu();
        } else {
            closeSearch();
            openMenu();
        }
    }

    function searchLogic() {
        if(searchTrigger.classList.contains('trigger-search-active')) {
            closeMenu();
            closeSearch();
        } else {
            closeMenu();
            openSearch();
        }
    }

    menuTrigger.addEventListener('click', function() {
        menuLogic();
    });

    searchTrigger.addEventListener('click', function() {
        searchLogic();
    });


    // Submenu Logic
    let menuItems = document.getElementsByClassName('js-menu-link');

    function closeSubmenu(clicking) {
        [].forEach.call(menuItems, function(obj) {
            obj.classList.remove('js-link-expanded');
            obj.nextElementSibling.style.maxHeight = null;
        });
    }

    function submenuLogic(clicking) {
        let submenu = clicking.nextElementSibling;

        if(clicking.classList.contains('js-link-expanded')) {
            closeSubmenu();
        } else {
            closeSubmenu();
            clicking.classList.add('js-link-expanded');
            submenu.style.maxHeight = submenu.scrollHeight + 'px';
        }
    }

    // Menu Triggers
    var newMenuTriggers = document.getElementsByClassName("newmenu-intro");

    // Menu logic
    for (var i = 0; i < newMenuTriggers.length; i++) {
        newMenuTriggers[i].addEventListener('click', function() {

            [].forEach.call(newMenuTriggers, function(obj) {

                var content = obj.parentNode.nextElementSibling;

                if(obj.classList.contains('is-triggered')) {

                    obj.classList.remove("is-triggered");

                    content.style.maxHeight = content.scrollHeight + "px";

                    setTimeout(function() {
                        content.style.maxHeight = null;
                    }, 10);

                } else {

                    obj.classList.add("is-triggered");

                    content.style.maxHeight = content.scrollHeight + "px";

                    setTimeout(function() {
                        content.style.maxHeight = "initial";
                    }, 210);

                }

            });

        });
    }

}