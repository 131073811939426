export const cookieSettings = {
    cookiename: "cookiesettings",
    cookieLifeTime: 1, // in months
};

export const cookieNoticeSettings = {
    centerMode: false,
    ignoredPages: [
        '/datenschutz',
    ],
    hasDeclineButton: true,
    // backgroundColor: 'blue',
    // textColor: 'red',
    useBackdrop: false,
    backdropColor: '#333',
    backdropOpacity: '0.8',
    autoEnableMandatory: false,
    autoEnableMandatoryOnPlaceholder: true,
};

export const cookieNoticeTexts = {
    "de": {
        initialHeadline: 'Cookies, externe Dienste & Datenschutz',
        customizeHeadline: 'Ihre Datenschutz-Einstellungen',
        explanationText: "Nach Ihrer Zustimmung verwenden wir Cookies um die Anzeige zu optimieren. Zur Analyse der Zugriffe auf unsere Website verwenden wir Google Analytics. Außerdem binden wir Skripte von YouTube und Mapbox ein. Dabei können personenbezogenen Daten an diese Anbieter übermittelt werden. Bitte klicken Sie <a href='/stiftung/kontakt/datenschutz'>hier</a>, um Informationen zum Datenschutz zu erhalten.",
        accept_preset_ButtonText: 'Akzeptieren',
        declineButtonText: 'Ablehnen und schließen',
        accept_all_ButtonText: 'Alle Cookies Akzeptieren',
        decline_all_ButtonText: 'Alle Ablehnen',
        customizeButtonText: 'Einstellungen',
        cancel_customizeButtonText: 'Abbrechen',
        saveButtonText: 'Speichern',
        closeButtonText: '×'
    },
    "en": {
        initialHeadline: 'Accept cookies & Privacy Policy?',
        customizeHeadline: 'Select cookies to accept',
        explanationText: "we use cookies. learn more <a href='/stiftung/kontakt/datenschutz'>here</a>",
        accept_preset_ButtonText: 'Accept preset',
        declineButtonText: 'Decline and close',
        accept_all_ButtonText: 'Accept all',
        decline_all_ButtonText: 'Decline all',
        customizeButtonText: 'Customize',
        cancel_customizeButtonText: 'Cancel',
        saveButtonText: 'Save',
        closeButtonText: '×'
    }
};

export const cookieSets = {
    "de": [
        {
            id: 'mandatory',
            name: 'Notwendige Cookies',
            description: 'Diese Cookies sind für die einwandfreie Funktion und das Design unserer Seiten nötig. Sie speichern keine personenbezogenen Daten.',
            mandatory: true,
            mandatory_text: "Dies muss gesetzt sein."
        }, {
            id: 'style',
            name: 'Styling Cookies',
            description: 'Styling Cookies speichern Ihre Layout-Einstellungen, wie zum Beispiel den Kontrast.'
        }, {
            id: 'gtm',
            name: 'Google Tag Manager',
            description: 'Diese Skripte und Cookies werden eingebunden, um mehr über die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, Bildschirmauflösung oder verwendeter Browser.'
        }, {
            id: 'mapbox',
            name: 'MapBox',
            description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes Mapbox darzustellen.'
        }, {
            id: 'youtube',
            name: 'YouTube',
            description: 'Diese Skripte und Cookies sind nötig, um YouTube Videos auf dieser Seite abzuspielen. Durch das Aktivieren können personenbezogene Daten an Google übertragen werden.'
        }, {
            id: 'fonts',
            name: 'Fonts',
            description: 'Fast fonts werden bei Ihrer Einbindung von den Servern von fonts.com geladen. Dabei könnte das Unternehmen die IPs der User theoretisch tracken. Das Unternehmen gibt an, dass es die IPs nicht erfasst.'
        }
    ],
    "en": [
        {
            id: 'mandatory',
            name: 'Mandatory cookies',
            description: 'Functional scripts are there so the website works as expected',
            mandatory: true,
            mandatory_text: "Dies muss gemacht werden."
        }, {
            id: 'style',
            name: 'Styling Cookies',
            description: 'Styling cookies are there to remember your style settings (e.g.: contrast)'
        }, {
            id: 'gtm',
            name: 'Google Tag Manager',
            description: 'Diese Skripte und Cookies werden eingebunden, um mehr über die Besucher unserer Website zu erfahren - wie zum Beispiel Herkunft, Bildschirmauflösung oder verwendeter Browser.'
        }, {
            id: 'mapbox',
            name: 'MapBox',
            description: 'Diese Skripte und Cookies sind nötig, um die Karten des Dienstes Mapbox darzustellen.'
        }, {
            id: 'youtube',
            name: 'YouTube',
            description: 'Diese Skripte und Cookies sind nötig, um YouTube Videos auf dieser Seite abzuspielen. Durch das Aktivieren können personenbezogene Daten an Google übertragen werden.'
        }, {
            id: 'fonts',
            name: 'Fonts',
            description: 'Fast fonts have to be loaded from the servers of fonts.fast.com. By loading the fonts the company in theory could track the IP. Yet it states that it does not track the IPs.'
        }
    ]
};
